.shell {
  display: flex;
  flex-direction: column;
  // height: 100vh;

  &>header {
    background: linear-gradient(lighten(#014d8f, 3%), #014d8f);
    user-select: none;
  }

  header[class="bx--header"] {
    &, * {
      color: white !important;

      &::before {
        background-color: white;
      }
    }
  }

  .menuItem {
    color: white;

    [aria-disabled="true"] {
      cursor: not-allowed;

      .menuItemWrapper * {
        color: #aaa !important;
      }
    }
  }

  .menuItemWrapper {
    display: flex;
    align-items: center;
    line-height: 1.2;

    .icon {
      margin-right: 0.5rem;
    }

    .label {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .shortcut {
      margin-left: 1rem;
    }
  }

  .menuItemLabel {
    color: white;
  }

  .title {
    margin-right: 2rem !important;
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .actionBar {
    display: flex;
    align-items: center;
    justify-content: stretch;

    &>* {
      height: 100%;
    }
  }

  *[class^="bx--header__name"] {
    padding-left: 0.2rem;
    padding-right: 1rem;
    margin-right: 1rem;

    svg {
      height: 1.0rem;
    }

    &:active, &:hover, &[aria-expanded="true"] {
      background-color: darken(#014d8f, 10%) !important;
    }
  }

  ul[class^="bx--header__menu"] {
    background: linear-gradient(lighten(#014d8f, 3%), #014d8f);
  }

  a[class^="bx--header__menu-item"] {
    &:active, &:hover, &[aria-expanded="true"] {
      background-color: darken(#014d8f, 10%) !important;
      color: #fff;
    }
  }

  .logo {
    //text-transform: lowercase;

    &, * {
      color: white !important;
      font-family: "Open Sans", sans-serif;
    }

    * {
      font-size: 1.318em;
    }

    span {
      font-weight: 400;
    }

    strong {
      font-weight: bold;
    }
  }

  main {
    flex-grow: 1;
    //overflow: scroll;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    //max-height: 50vh;
    position: relative;
    //max-height: 90vh;
  }

}