/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bx--header__submenu ul, bx--header__submenu .bx--header__menu-item {
  min-width: 20rem;
}

ul.bx--overflow-menu-options, .bx--overflow-menu-options__btn {
  min-width: 15rem;
}

/*h1, h2, h3, h4, h5, h6, p, div, section {*/
/*  border: thin solid #aaa;*/
/*  padding: 0.5rem;*/
/*  position: relative;*/
/*}*/

/*h1::before, h2::before, h3::before, h4::before, h5::before, h6::before, div::before, p::before, section::before {*/
/*  position: absolute;*/
/*  top: -2rem;*/
/*  left: 0rem;*/
/*  font-size: 1rem;*/
/*  font-weight: 200;*/
/*  !*font-style: italic;*!*/
/*  !*opacity: 0.8;*!*/
/*  height: 2rem;*/
/*  padding: 0 0.5rem;*/
/*  background: #999;*/
/*  color: white;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*h1::before {*/
/*  content: "H1";*/
/*}*/

* {
  box-sizing: border-box;
}

/*h2::before {*/
/*  content: "H2";*/
/*}*/

/*h3::before {*/
/*  content: "H3";*/
/*}*/

/*h4::before {*/
/*  content: "H4";*/
/*}*/

/*h5::before {*/
/*  content: "H5";*/
/*}*/

/*h6::before {*/
/*  content: "H6";*/
/*}*/

/*p::before {*/
/*  content: "P";*/
/*}*/

/**[data-slate-string] {*/
/*  padding: 0.5rem;*/
/*  margin-right: 1rem;*/
/*  background: #ddd;*/
/*  cursor: move;*/
/*}*/

.disable-user-select {
  &, * {
    user-select: none !important;
  }
}

body {
  // overflow: hidden;
}