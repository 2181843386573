.container {
    padding: 2rem;
    flex-grow: 1;
    overflow: auto;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 1;
        margin-bottom: 1.618rem;
        padding-bottom: 1.618rem;
        border-bottom: 0.2rem solid #014d8f;

        h1 {
            font-size: 2.618rem;
            font-weight: bold;
        }

        button {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &, * { vertical-align: middle; }
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1.618rem;
        margin-left: -1.618rem;

        .project {
            width: calc((100% - 1.618rem * 4) / 4);
            // border: thin solid black;
            margin-left: 1.618rem;
            margin-bottom: 1.618rem;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 100%;
                height: auto;
                min-height: 10em;
                margin-bottom: 0.75rem;
                border-radius: 0.25rem;
                display: block;
                overflow: hidden;
                background: #eee;
            }

            text-align: center;
            font-weight: 600;
        }
    }

    .footer {
        margin-top: 1.618rem;
    }
}