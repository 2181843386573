.container {

}

.wrapper {
    display: flex;
    align-items: center;
    height: 100%;
}

.title {
    font-family: "Open Sans";
    font-size: 1rem;
    color: currentColor;
    display: flex;
    align-items: center;
}

.actionButtons {
    display: flex;

    button + button {
        margin-left: 0.618rem;
    }

    a {
        margin: 0 1rem;
    }
}